import ls from 'lib/local-storage'
import api from 'lib/api'
import objects from "lib/objects";
import { param } from 'lib/fns'
import React from "react";

const TTL = 60 * 60 * 1000
const KEY = 'soundtrack:prices:'

export const PRICING_PROPERTIES = {
  YEARLY: 'yearly',
  YEARLY_PER_MONTH: 'yearly.perMonth',
  YEARLY_PER_MONTH_ORIGINAL_PRICE: 'yearly.perMonthOriginal',
  YEARLY_DISCOUNT_PERCENTAGE: 'yearly.discountPercentage',
  MONTHLY: 'monthly',
  MONTHLY_ORIGINAL_PRICE: 'monthly.original',
  VOUCHER_DISCOUNT_PERCENTAGE: 'voucherDiscount'
}

function getPriceFromObject(obj, property, tier) {
  if (obj.price_list) {
    for (const price of obj.price_list) {
      if (price && price.billing_cycle === property && price.tier === tier) {
        return price
      }
    }
  }
  return null
}

function normalizePlanStructure(obj, defaultPrice, defaultTrialDays) {
  const currency = obj && (obj.iso_currency || 'USD')

  // Standard plans (for online-sales)
  const pricingPlans = {
    'essential': 'tier-1',
    'unlimited': 'tier-3'
  }

  const prices = {}

  for (const key of Object.keys(pricingPlans)) {

    const monthlyPriceInformation = getPriceFromObject(obj, 'monthly', pricingPlans[key])
    const yearlyPriceInformation = getPriceFromObject(obj, 'yearly', pricingPlans[key])

    if (monthlyPriceInformation && yearlyPriceInformation) {
      const monthlyPrice = monthlyPriceInformation.prices?.[0] ?? defaultPrice
      const monthlyOriginalPrice = monthlyPriceInformation.applied_discount?.original_prices?.[0] ?? null

      const yearlyPerMonthPrice = yearlyPriceInformation.prices?.[0] ?? defaultPrice
      const yearlyPerMonthOriginalPrice = yearlyPriceInformation.applied_discount?.original_prices?.[0] ?? null
      const yearlyPrice = Math.round((yearlyPerMonthPrice * 12 + Number.EPSILON) * 100) / 100
      const yearlyOriginalPrice = yearlyPerMonthOriginalPrice ? Math.round((yearlyPerMonthOriginalPrice * 12 + Number.EPSILON) * 100) / 100  : null

      const monthlyTrialDays = monthlyPriceInformation.trial_days ?? defaultTrialDays
      const yearlyTrialDays = yearlyPriceInformation.trial_days ?? defaultTrialDays

      const monthlyHasTrial = monthlyTrialDays > 0
      const yearlyHasTrial = yearlyTrialDays > 0

      const discountPercentage = 100 - Math.round((yearlyPerMonthPrice / monthlyPrice) * 100)

      prices['voucherDiscount'] = monthlyOriginalPrice ? 100 - Math.round((monthlyPrice / monthlyOriginalPrice) * 100) : null

      prices[key] = {
        [PRICING_PROPERTIES.YEARLY]: {
          'price': yearlyPrice,
          'originalPrice': yearlyOriginalPrice,
          currency,
          'trialDays': yearlyTrialDays,
          'hasTrial': yearlyHasTrial,
          discountPercentage,
          'perMonth': {
            'price': yearlyPerMonthPrice,
            currency
          },
          'perMonthOriginal': {
            'price': yearlyPerMonthOriginalPrice,
            currency
          }
        },
        [PRICING_PROPERTIES.MONTHLY]: {
          'price': monthlyPrice,
          currency,
          'original': {
            'price': monthlyOriginalPrice,
            currency
          },
          'trialDays': monthlyTrialDays,
          'hasTrial': monthlyHasTrial,
        }
      }
    }
  }

  return prices
}

const normalizePriceStructure = (obj = {}, isoCountry, voucher) => {
  const prices = normalizePlanStructure(obj, 49, 14,)
  return {
    ...prices,
    isoCountry,
    voucher
  }
}

const getPricesFromApi = async (isoCountry, voucher) => {
  const params = { iso_country: isoCountry }
  if (voucher) {
    params.voucher_code = voucher
  }
  const pricing = await api.get('https://billing.api.soundtrackyourbrand.com/pricing?' + param(params))
  return normalizePriceStructure(pricing, isoCountry, voucher)
}

const getPrices = async (voucher) => {
  const { country } = await api.location()
  const hasVoucher = voucher !== null
  const storageKey = hasVoucher ? KEY + country + ':' + voucher : KEY + country
  let prices = ls.get(storageKey)
  if (!prices) {
    prices = await getPricesFromApi(country, voucher)
    ls.set(storageKey, prices, TTL)
  }
  return prices
}

const formatPrice = (price, currency) => {
  if (price && currency) {
    return `${price} ${currency}`
  } else return null
}

const renderPrice = (prices, pricePlan, priceProperty, format = true) => {

  if (prices.loading) {
    return <span className="text--is-throbbing mb-0"></span>;
  }

  const priceForSelectedPricePlan = objects.get(
    prices,
    pricePlan
  );

  const priceForSelectedProperty = objects.get(
    priceForSelectedPricePlan,
    priceProperty
  );

  if (typeof priceForSelectedPricePlan === 'undefined' || typeof priceForSelectedProperty === 'undefined') {
    return null
  }

  if (format) {
    const formattedPrice = formatPrice(
      priceForSelectedProperty.price,
      priceForSelectedProperty.currency
    );
    return <span>{formattedPrice}</span>;
  } else {
    return <span>{priceForSelectedProperty}</span>;
  }
};

const pricing = {
  renderPrice,
  getPrices,
  PRICING_PROPERTIES,
};

export default pricing;