import { trackingConfig as config } from "../../tracking-config";

export function isBrowser() {
  // Check if the environment is Node.js
  if (typeof process === "object" && typeof require === "function") {
    return false;
  }
  // Check if the environment is a
  // Service worker
  if (typeof importScripts === "function") {
    return false;
  }
  // Check if the environment is a Browser
  if (typeof window === "object") {
    return true;
  }
}

export const createTrackingEvent = (
  eventName,
  metadata,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) => {
  tracking.then((tracking) => {
    tracking.track(eventName, metadata);
    if (eventCategory) {
      tracking.gtm(
        eventCategory || "Click",
        eventAction,
        eventLabel || "",
        eventValue
      );
    }
  });
};

export const participate = (name, variation) => {
  tracking.then(tracking => tracking.participate(name, variation))
}

export const afLink = (props) => tracking
  .then(tracking => 
    tracking.afLink(props)
)

export const tracking = isBrowser()
  ? import("@soundtrackyourbrand/tracking.js").then((Tracking) => {
    const _tracking = new Tracking.default({
      clientId: config.clientId,
      debug: config.debug,
      gtm: config.gtm,
      mixpanel: config.mixpanel,
      intercom: config.intercom,
      appsflyer: config.appsflyer,
      hotjar: config.hotjar,
    });

    // Show consent modal (or implicitly register consent) if consent has not yet been given
    _tracking.requestConsent();

    // Add 'Current Path' as default tracking event attribute
    _tracking.on('track', (eventName, data) => {
      data['Current Path'] = window.location.pathname
      data['Current Title'] = document.title
    })

    window.tracking = _tracking

    return _tracking
  })
  : new Promise(() => { });
