const storage = isSupported('localStorage') ? window.localStorage : generateFallbackStorage()

module.exports = {
  storage,
  set(variable, value, ttl_ms) {
    const data = { value, expires_at: ttl_ms ? (new Date().getTime() + ttl_ms / 1) : null }
    storage.setItem(String(variable), JSON.stringify(data))
  },
  get(variable) {
    const data = JSON.parse(storage.getItem(String(variable)))
    if (data !== null) {
      if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
        storage.removeItem(String(variable))
      } else {
        return data.value
      }
    }
    return null
  }
}

function generateFallbackStorage() {
  const store = {
    setItem(id, val) {
      return store[id] = String(val)
    },
    getItem(id) {
      return hasProp(store, id) ? String(store[id]) : null
    },
    removeItem(id) {
      return delete store[id]
    },
    clear() {
      for (const key in store) {
        if (hasProp(store, key) && typeof store[key] !== 'function') {
          delete store[key]
        }
      }
    }
  }
  return store
}

function isSupported(storage) {
  try {
    const x = '__storage_test__'
    window[storage].setItem(x, x)
    window[storage].removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

function hasProp(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}