import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { tracking, createTrackingEvent } from "lib/tracking";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import FacebookIcon from "assets/icons/facebook.svg";
import InstagramIcon from "assets/icons/instagram.svg";
import LinkedinIcon from "assets/icons/linkedin.svg";
import TwitterIcon from "assets/icons/twitter.svg";
import YoutubeIcon from "assets/icons/youtube.svg";
import * as FooterStyling from "./Footer.module.scss";
import StructuredTextRenderer from "../StructuredTextRenderer/StructuredTextRenderer";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: datoCmsFooter {
        id: originalId
        columnOne {
          __typename
          ...ExternalLink
          ...InternalLink
        }
        columnTwo {
          __typename
          ...ExternalLink
          ...InternalLink
        }
        columnThree {
          __typename
          ...ExternalLink
          ...InternalLink
        }
        fineprint {
          value
        }
      }
    }
  `);

  const footerData = data.footer;

  const trackClick = (linkText) => {
    createTrackingEvent(
      "Storefront - Footer Nav Click",
      {
        "Footer Click Name": linkText,
      },
      "Click",
      {
        "Footer Click Name": linkText,
      },
      linkText
    );
  };

  const Column = ({ column }) => {
    return (
      <ul className="subtle text18">
        {column.map((link) => {
          switch (link.__typename) {
            case "DatoCmsExternallink":
              return (
                <li key={link.id}>
                  <a href={link.url} onClick={() => trackClick(link.text)}>
                    {link.text}
                  </a>
                </li>
              );
            case "DatoCmsInternallink":
              return (
                <li key={link.id}>
                  <Link
                    onClick={() => trackClick(link.text)}
                    to={`/${link.slug.slug}`}
                  >
                    {link.text}
                  </Link>
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    );
  };

  return (
    <Section row="r-bottom" className={FooterStyling.background}>
      <div className="col c-6 m-2">
        <Column column={footerData.columnOne} />
      </div>
      <div className="col c-6 m-2">
        <Column column={footerData.columnTwo} />
      </div>
      <div className="col c-6 m-2">
        <Column column={footerData.columnThree} />
      </div>
      <div className="col c-12 m-2">
        <ul className="subtle">
          <a
            className={FooterStyling.footerIcon}
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UC2p1XbFBFxaVed_JpidJs8A"
          >
            <YoutubeIcon />
          </a>
          <a
            className={FooterStyling.footerIcon}
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/SoundtrackYour"
          >
            <TwitterIcon />
          </a>
          <a
            className={FooterStyling.footerIcon}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/soundtrackyourbrand"
          >
            <LinkedinIcon />
          </a>
          <a
            className={FooterStyling.footerIcon}
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/soundtrackyourbrand"
          >
            <FacebookIcon />
          </a>
          <a
            className={FooterStyling.footerIcon}
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/soundtrackyourbrand/"
          >
            <InstagramIcon />
          </a>
        </ul>
      </div>
      <div className="col c-12 m-4 mb-5">
        <div className="subtle">
          <a
            className={`mb-5 flex r-middle ${FooterStyling.footer__logo}`}
            href="/"
          >
            <Icon name="logo" className={FooterStyling.footerLogo} />
            <span className={`${FooterStyling.footer__logoText} text4`}>
              soundtrack{" "}
              <span className={FooterStyling.footer__logo__textLowOpacity}>
                your brand
              </span>
            </span>
          </a>
        </div>

        <div className="subtle text3">
          <p className="mb-0">Soundtrack Your Brand ®</p>
          <StructuredTextRenderer
            data={footerData.fineprint}
            isParagraphClassName="mb-0 subtle"
            isLinkClassName="mb-0 subtle"
          />
          <button
            className={`subtle ${FooterStyling.cookiePreferences}`}
            onClick={() =>
              tracking.then((tracking) => {
                tracking.requestConsent(true);
              })
            }
          >
            cookie preferences
          </button>
        </div>
      </div>
    </Section>
  );
};

export default Footer;
