import overrides from 'lib/overrides'
import { memoize, single, param } from 'lib/fns'

function request(settings, jsonResponse) {
  const init = {
    method: settings.method || 'GET'
  }
  if (jsonResponse) {
    init.headers = {
      'x-api-version': '10',
      'accept': 'application/json',
      'content-type': 'application/json; charset=UTF-8'
    }
  }
  return fetch(settings.url, init)
}

export async function get(url, jsonResponse = true) {
  const response = await request({ method: 'GET', url }, jsonResponse)
  return jsonResponse ? response.json() : response.text()
}

export async function sendDataToWebToLead(data) {
  // TODO: Replace this with a proper integration with Salesforce REST API, this is temporary only.

  const requestOptions = {
    method: 'POST',
    mode: 'no-cors', // WebToLead limitation, switch to Rest API to use CORS
  };

  return fetch(
    `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&` + param(data),
    requestOptions
  )
}

export async function signUpAffiliate(data) {
  const referralId = new URLSearchParams(window.location.search).get('p');
  const body = {
    "firstname": data.firstName,
    "lastname": data.lastName,
    "email": data.email,
    "company": data.company,
    "contact_address": {
      "city": data.city,
      "zip_postal_code": data.zipPostalCode,
      "country_code": data.countryCode,
    },
    "tax_id": data.taxId,
    "billing": {
      "payment_type": data.receptionIdentifier ? "Paypal" : "None",
      "billing": {
        "paypal_reception_method": data.receptionIdentifier ? "Paypal Account" : "",
        "reception_identifier": data.receptionIdentifier
      }
    },
    "custom_field_values": [
      {
        "network_signup_custom_field_id": 3,
        "field_value": data.custom3
      }
    ],
    "referrer_encoded_value": referralId
  }

  const headers = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  };

  return fetch(`https://us-central1-syb-production-ge.cloudfunctions.net/everflow-api`, requestOptions).then((response) => {
    return response
  })
}

const locationCache = memoize.localStorage('soundtrack:country', 3600e3)(function () {
  return get('https://auth.api.soundtrackyourbrand.com/determine_location')
})


export const location = single(function () {
  const country = overrides.get('country')
  return new Promise((resolve) => {
    resolve(country ? { country } : locationCache())
  })
})

const api = {
  get,
  sendDataToWebToLead,
  signUpAffiliate,
  location,
};

export default api;