exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-templates-affiliate-affiliate-js": () => import("./../../../src/templates/affiliate/affiliate.js" /* webpackChunkName: "component---src-templates-affiliate-affiliate-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-guide-page-js": () => import("./../../../src/templates/guidePage.js" /* webpackChunkName: "component---src-templates-guide-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legalPage.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-landing-page-js": () => import("./../../../src/templates/partnerLandingPage.js" /* webpackChunkName: "component---src-templates-partner-landing-page-js" */)
}

