const { GATSBY_SYB_ENV } = process.env
const experimentIsLive = false // Set to true when launching experiment
const isSybProd = GATSBY_SYB_ENV !== 'development' || experimentIsLive;

export const trackingConfig = {
  clientId: 'storefront',
  debug: !isSybProd, // optional: enables debugging outside production
  gtm: 'GTM-KF7MWXQ',
  mixpanel: (isSybProd
    ? '4c998ee468b01ae18c38c5745b74cbbb'
    : 'fd865fd9c438ca7ccfdd793b911ab653'
  ),
  // intercom: (isSybProd ? 'isecldm8' : 'tahstcdm'),
  appsflyer: (isSybProd ? '392782bb-59d8-4ceb-ad4b-09804032d02c' : undefined),
  hotjar: isSybProd,
}

