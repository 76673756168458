import * as cookie from 'tiny-cookie'

const queryString =  {
  decode(str, allowedProps) {
    if (!str) {
      return {}
    }
    try {
      return (str[0] === '?' ? str.substring(1, str.length) : str)
        .split('&')
        .map(x => x.split('='))
        .reduce((obj, [key, value]) => {
          if (!allowedProps || allowedProps.indexOf(key) >= 0) {
            obj[key] = decodeURIComponent(value)
          }
          return obj
        }, {})
    } catch (e) {
      return {}
    }
  },

  encode(obj, allowedProps = Object.keys(obj)) {
    if (!obj) {
      return ''
    }
    return allowedProps.reduce((ary, key) => {
      if (obj[key]) {
        ary.push(key + '=' + encodeURIComponent(obj[key]))
      }
      return ary
    }, []).join('&')
  },

  fromURL(allowedProps) {
    if (typeof window === 'undefined') return null
    return this.decode(window.document.location.search, allowedProps)
  },

  fromCookie(cookieName, allowedProps) {
    return this.decode(cookie.get(cookieName), allowedProps)
  },
}

export default queryString