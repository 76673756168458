import React from 'react';
import IconSprite from "@soundtrackyourbrand/ui/dist/assets/icons/sprite.svg";

const CustomSprite = () => {
  return (
    <div style={{ display: 'none' }}>
      <IconSprite />
    </div>
  );
};

export default CustomSprite;