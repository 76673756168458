const urls = {
  _current: null,

  current() {
    return this._current ? this._current : this._current = this.parse(window.location.toString())
  },

  cookieDomain(hostname) {
    if (hostname === 'localhost') { return '' }
    // Leave IP addresses as-is
    if (!hostname || /^\d{1,3}(?:\.\d{1,3}){3}$/.test(hostname)) {
      return hostname || ''
    }
    // Attempt to extract domain ancestor containing 'soundtrack'
    const sybMatch = hostname.match(/(?:^|\.)(soundtrack[^.]*\..+)/)
    return sybMatch
      ? '.' + sybMatch[1]
      : hostname
  },

  parse(str = '') {
    const a = window.document.createElement('a')

    a.href = str

    return {
      cookieDomain: this.cookieDomain(a.hostname),
      host: a.host,
      hostname: a.hostname,
      path: a.pathname.replace(/(.)\/$/, '$1'),
      protocol: a.protocol,
      hash: a.hash,
      search: a.search
    }
  }
}

export default urls