import React from 'react';
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { get } from 'tiny-cookie';
import debounce from 'lodash/debounce';

import {
  EXPERIMENTS_NAMESPACE,
  participateAsUnvalidated,
} from '@soundtrackyourbrand/experiments.js'

import { loadExperiments, saveExperiments, experimentsMiddleware } from './experiments';
import { isBrowser, tracking } from '../lib/tracking';
import rootReducer from '.';

const EXPERIMENT_COOKIE = 'syb.exp'

const composeEnhancer = (isBrowser() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = reduxCreateStore(
  rootReducer,
  loadExperiments(),
  composeEnhancer(applyMiddleware(experimentsMiddleware)),
);

store.subscribe(
  debounce(() => {
    saveExperiments(store.getState());
  }, 1000)
);

const ReduxWrapper = ({ children }) => {
  React.useEffect(() => {
    // Parse experiment from cookies
    if (isBrowser()) {
      const [storedExperiment = '', experimentVariation] = get(EXPERIMENT_COOKIE)?.split('~') || []
      const experimentName = decodeURIComponent(storedExperiment)
      const state = store.getState()
      const storedVariation = state[EXPERIMENTS_NAMESPACE][experimentName]

      if (experimentVariation && experimentVariation !== storedVariation) {
        store.dispatch(participateAsUnvalidated(experimentName, experimentVariation))
      }
    }
  }, [])

  return <Provider store={store} children={children} />
}

export default ReduxWrapper
