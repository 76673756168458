import React, { useState } from "react";
import { createTrackingEvent } from "lib/tracking";
import { Link } from "gatsby";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import * as HamburgerStyling from "./HamburgerMenu.module.scss";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";

const HamburgerMenu = ({
  menuLinks,
  menu,
  showHamburger,
  setShowHamburger,
  signupLink,
  loginLink,
}) => {
  //Tracking
  const trackGroupClick = (eventName, linkText) => {
    createTrackingEvent(
      eventName,
      {
        "Nav Click Name": linkText,
      },
      "Click",
      {
        eventName,
      },
      linkText
    );
  };

  const trackClick = (eventName, action) => {
    createTrackingEvent(eventName, {}, "Click", action);
  };

  //Dropdown
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      className={
        showHamburger
          ? `${HamburgerStyling.hamburgerWrapper} ${HamburgerStyling.active} hide-from-l `
          : `${HamburgerStyling.hamburgerWrapper}`
      }
    >
      <div
        className={`${HamburgerStyling.hamburgerContent} hide-from-l layout-row `}
      >
        <ul className={`icon-list ${HamburgerStyling.navItems}`}>
          <li className="p0 ">
            <div className="dropdownContainer">
              <div className="mb-7">
                <div
                  aria-hidden="true"
                  role="button"
                  tabIndex={0}
                  className="text-decoration-none text22"
                  onClick={() => {
                    setShowDropdown((showDropdown) => !showDropdown);
                    if (!showDropdown) {
                      trackClick(
                        "Storefront - Open Business types Dropdown",
                        "Open Business types Dropdown"
                      );
                    }
                  }}
                >
                  <span>
                    business types
                    <span className={HamburgerStyling.dropdownBtn}>
                      <Icon
                        name="chevron-down"
                        className={
                          showDropdown
                            ? `arrowDropdown arrowDropdownActive`
                            : `arrowDropdown`
                        }
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div
                className={
                  showDropdown
                    ? `${HamburgerStyling.dropdownContent} ${HamburgerStyling.active} hide-from-l`
                    : `${HamburgerStyling.dropdownContent}`
                }
              >
                {menu.map((category, index) => {
                  return (
                    <ul
                      key={index}
                      className={`icon-list text18 mb-0 ${HamburgerStyling.category}`}
                    >
                      <p>{category.node.title}</p>
                      {category.node.treeChildren.map((item) => {
                        return (
                          <li key={item.id}>
                            <Link
                              onClick={() => {
                                setShowHamburger(
                                  (showHamburger) => !showHamburger
                                );
                                trackGroupClick(
                                  "Storefront - Business Types Nav Click",
                                  item.title
                                );
                              }}
                              to={`/${item.slug.slug}`}
                              className={`text-decoration-none ${HamburgerStyling.categorySubTitle}`}
                            >
                              {item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            </div>
          </li>
          {menuLinks.map((item) => {
            return (
              <li key={item.id} className="p0 text22 mb-7">
                <Link
                  to={`/${item.slug.slug}`}
                  onClick={() => {
                    trackGroupClick("Storefront - Main Nav Click", item.text);
                    setShowHamburger((showHamburger) => !showHamburger);
                  }}
                  className="text-decoration-none"
                >
                  {item.text}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className={HamburgerStyling.btnsWrapper}>
          <Button
            tag="a"
            primary
            onClick={() => {
              setShowHamburger((showHamburger) => !showHamburger);
              trackClick("Storefront - Click Signup CTA");
            }}
            href={signupLink}
          >
            try it free
          </Button>
          <div className="mono-container">
            <Button
              tag="a"
              primary
              onClick={() => {
                setShowHamburger((showHamburger) => !showHamburger);
                trackClick("Storefront - Click Login");
              }}
              href={loginLink}
            >
              log in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
