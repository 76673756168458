// extracted by mini-css-extract-plugin
export var active = "Sidebar-module--active--c124c";
export var card = "Sidebar-module--card--f4486";
export var cardText = "Sidebar-module--cardText--f206c";
export var categorySubTitle = "Sidebar-module--categorySubTitle--8efef";
export var categoryTitle = "Sidebar-module--categoryTitle--7447b";
export var menuCategories = "Sidebar-module--menuCategories--d73f1";
export var menuCategories_Container = "Sidebar-module--menuCategories_Container--83d22";
export var sidebarClose = "Sidebar-module--sidebarClose--060da";
export var sidebarContainer = "Sidebar-module--sidebarContainer--74ad8";
export var sidebarWrapper = "Sidebar-module--sidebarWrapper--de684";