import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import cn from 'classnames'
import { Button } from '@soundtrackyourbrand/ui/src/Button'
import { Icon } from '@soundtrackyourbrand/ui/src/Icon'
import { Helmet } from 'react-helmet'

import { createTrackingEvent, isBrowser } from 'lib/tracking'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Sidebar from '../Sidebar/Sidebar'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import * as styles from './Header.module.scss'

const Header = ({ navbarColors, mainRef }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          loginUrl
          signupUrl
        }
      }
      datoCmsHeader {
        links {
          ...InternalLink
        }
      }
      defaultSeo: datoCmsNotFound {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
      datoCmsFaviconMetaTags {
        tags
      }
      menu: allDatoCmsSidebar(filter: { root: { eq: true } }) {
        edges {
          node {
            id: originalId
            title
            treeChildren {
              id: originalId
              title
              slug {
                slug
              }
            }
          }
        }
      }
      datoCmsSidebardemobox {
        text {
          value
          blocks {
            __typename
            ...Link
          }
        }
      }
    }
  `);

  const trackGroupClick = (eventName, linkText) => {
    const metaData = {
      "Nav Click Name": linkText,
    };
    createTrackingEvent(
      eventName,
      metaData,
      "Navigation",
      "Navigate to " + linkText,
      linkText
    );
  };

  const headerData = data.menu.edges;
  const sidebarDemo = data.datoCmsSidebardemobox;
  const navBarTheme = navbarColors;
  const popExperiment = navBarTheme === 'redWhitePop'

  const [showSidebar, setShowSideBar] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0)

  const toggleHamburger = () => {
    setShowHamburger((showHamburger) => !showHamburger)
    !showHamburger && setScrollPosition(window?.pageYOffset)
  }
  useEffect(() => {
    if(!showHamburger) {
      window?.scrollTo(0, scrollPosition)
    }
  }, [showHamburger, scrollPosition])

  let sidebarRef = useRef();
  let observerRef = useRef();
  const navbarRef = useRef();

  useEffect(() => {
    const hero = document.querySelector("main .hero-container");
    const target = document.querySelector("body main").firstElementChild;

    if (hero) {
      mainRef.current.classList.remove(styles.noHero);
      if (isBrowser()) {
        observerRef.current = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              entry.isIntersecting
              ? navbarRef.current.classList.remove(
                styles.navBackground
              )
              : navbarRef.current.classList.add(
                styles.navBackground
              );
            });
          },
          {
            rootMargin: "-90px 0px 0px 0px",
            threshold: 0.01,
          }
        );
        observerRef.current.observe(target);
      }
    } else {
      mainRef.current.classList.add(styles.noHero);
      navbarRef.current.classList.remove(styles.navBackground);
    }

    return () => {
      if (observerRef.current && isBrowser()) {
        observerRef.current.disconnect();
      }
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!sidebarRef.current.contains(event.target)) {
        setShowSideBar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <header ref={sidebarRef}>
      <HelmetDatoCms
        seo={data.defaultSeo.seoMetaTags}
        favicon={data.datoCmsFaviconMetaTags}
      />
      <Helmet
        bodyAttributes={{
          class: showHamburger ? "fixed" : "",
        }}
      />
      <div
        ref={navbarRef}
        className={
          cn(
            'nav',
            styles.wrapperDesktop,
            styles[`${navBarTheme}Wrapper`],
            showHamburger && [styles.active, styles.navBackground]
            )
        }
      >
        <div
          className={cn('layout-row r-middle row', styles[navBarTheme])}
        >
          <div className="c-grow">
            <Link
              onClick={() => setShowSideBar((showSidebar) => false)}
              to="/"
              className={cn('text-decoration-none', styles.logoWrapper)}
            >
              <Icon name="logo" className={styles.headerLogo} />
              <span className={styles.logoText}>
                Soundtrack {!popExperiment && <span>Your Brand</span>}
              </span>
            </Link>
          </div>
          <button
            className={cn('hide-from-l c-auto', styles.hamburger)}
            onClick={toggleHamburger}
          >
            <i
              className={
                cn(styles.hamburgerInner, showHamburger && styles.open)
              }
            ></i>
          </button>
          <div className="c-auto inline-elements hide-to-l ">
            <Button
              flat
              className={cn('p0', styles.navItem)}
              onClick={() => {
                setShowSideBar((showSidebar) => !showSidebar);
                if (!showSidebar) {
                  createTrackingEvent(
                    "Storefront - Open Business types Dropdown",
                    {},
                    "Navigation",
                    "Open Business types Dropdown",
                    "Business types"
                  );
                }
              }}
            >
              business types
              <Icon
                name="chevron-down"
                className={
                  cn('arrowDropdown', showSidebar && 'arrowDropdownActive')
                }
              />
            </Button>
            {data.datoCmsHeader.links.map((link) => (
              <Button
                flat
                tag={Link}
                key={link.id}
                onClick={() => {
                  setShowSideBar(false);
                  trackGroupClick("Storefront - Main Nav Click", link.text);
                }}
                to={`/${link.slug.slug}`}
                className={cn('p0 text-decoration-none', styles.navItem)}
              >
                {link.text}
              </Button>
            ))}
            <a
              onClick={() => {
                setShowSideBar(false);
                createTrackingEvent(
                  "Storefront - Click Login",
                  {},
                  "Navigation",
                  "Navigate to Login",
                  "Login"
                );
              }}
              href={data.site.siteMetadata.loginUrl}
              className={cn('btn btn--flat p0 text-decoration-none', styles.navItem)}
            >
              log in
            </a>
            <a
              onClick={() => {
                setShowSideBar(false);
                createTrackingEvent(
                  "Storefront - Click Signup CTA",
                  {},
                  "Navigation",
                  "Navigate to Signup",
                  "Signup"
                );
              }}
              href={data.site.siteMetadata.signupUrl}
              className={cn('btn btn--primary text-decoration-none', styles.navItemBtn)}
            >
              try it free
            </a>
          </div>
        </div>
      </div>
      <Sidebar
        sidebarData={headerData}
        showSidebar={showSidebar}
        sidebarDemo={sidebarDemo}
        setShowSideBar={setShowSideBar}
      />
      <HamburgerMenu
        menuLinks={data.datoCmsHeader.links}
        menu={headerData}
        showHamburger={showHamburger}
        setShowHamburger={setShowHamburger}
        signupLink={data.site.siteMetadata.signupUrl}
        loginLink={data.site.siteMetadata.loginUrl}
      />
    </header >
  );
};

export default Header;
