
require("./src/style/style.scss");

const React = require("react");
const Layout = require("./src/components/Layout/Layout").default;
const ReduxWrapper = require('/src/store/ReduxWrapper').default;

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {

  return <ReduxWrapper>
    <Layout {...props}>{element}</Layout>
  </ReduxWrapper>
}