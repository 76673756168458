import React from "react";
import { createTrackingEvent } from "lib/tracking";
import { Link } from "gatsby";
import * as SidebarStyling from "./Sidebar.module.scss";
import StructuredTextRenderer from "../StructuredTextRenderer/StructuredTextRenderer";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";

const Sidebar = ({ sidebarData, showSidebar, setShowSideBar, sidebarDemo }) => {
  const trackGroupClick = (eventName, linkText) => {
    createTrackingEvent(
      eventName,
      {
        "Nav Click Name": linkText,
      },
      "Click",
      {
        "Nav Click Name": linkText,
      },
      linkText
    );
  };

  const trackClick = (eventName) => {
    createTrackingEvent(eventName, {}, "Click", "Book a Demo Nav CTA Click");
  };

  return (
    <div
      className={
        showSidebar
          ? `${SidebarStyling.sidebarWrapper} ${SidebarStyling.active} hide-to-l`
          : `${SidebarStyling.sidebarWrapper}`
      }
    >
      <div className={SidebarStyling.sidebarContainer}>
        <div className={SidebarStyling.menuCategories_Container}>
          <Icon
            name="cross"
            className={SidebarStyling.sidebarClose}
            onClick={() => setShowSideBar((showSidebar) => false)}
          />
          {sidebarData.map((item, index) => {
            return (
              <div key={index} className={SidebarStyling.menuCategories}>
                <p className={`text5 mb-6 ${SidebarStyling.categoryTitle}`}>
                  {item.node.title}
                </p>
                {item.node.treeChildren.map((subItem) => {
                  return (
                    <Link
                      to={`/${subItem.slug.slug}`}
                      onClick={() => {
                        setShowSideBar((showSidebar) => false);
                        trackGroupClick(
                          "Storefront - Business Types Nav Click",
                          subItem.title
                        );
                      }}
                      key={subItem.id}
                      className={`mb-3 text-decoration-none ${SidebarStyling.categorySubTitle}`}
                    >
                      {subItem.title}
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={SidebarStyling.card}>
          <StructuredTextRenderer
            data={sidebarDemo.text}
            isParagraphClassName={`mb-7 ${SidebarStyling.cardText}`}
            onButtonClick={() => {
              setShowSideBar((showSidebar) => false);
              trackClick("Storefront - Book a Demo Nav CTA Click");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
