export function get(obj, path, fallback) {
  if (!path) { return fallback }
  if (!Array.isArray(path)) { path = path.split('.') }
  if (obj && obj.getIn) { return obj.getIn(path, fallback) }
  return path.reduce((tree, path) => {
    return tree ? (tree.get ? tree.get(path) : tree[path]) : fallback
  }, obj)
}

export function set(obj, path, value) {
  if (!Array.isArray(path)) { path = path.split('.') }
  if (obj && obj.setIn) { return obj.setIn(path, value) }
  const last = path.pop()
  const leaf = get(obj, path)
  if (leaf) leaf[last] = value
  return obj
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

const objects = { 
  get, 
  set, 
  clone
};

export default objects