import React from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderMarkRule, renderNodeRule, StructuredText } from "react-datocms";
import { Link } from "gatsby";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";

import {
  isHeading,
  isParagraph,
  isLink,
  isListItem,
} from "datocms-structured-text-utils";
import { isExternalLink } from "lib/fns";
import colors from "lib/colors";

const QuoteBlock = loadable(() => import('blocks/Quote/Quote'));
const Video = loadable(() => import('../Video/Video'));
const AppDownloads = loadable(() => import('blocks/AppDownloads/AppDownloads'))
const DownloadDropdown = loadable(() => import('blocks/DownloadDropdown/DownloadDropdown'))
const Table = loadable(() => import('blocks/Table/Table'))
const List = loadable(() => import('blocks/List/List'))
const LinkButton = loadable(() => import('blocks/LinkButton/LinkButton'))
const PublicationGrid = loadable(() => import('blocks/PublicationGrid/PublicationGrid'));
const Playlist = loadable(() => import('blocks/Playlist/Playlist'));
const LinkTrackCopy = loadable(() => import('blocks/LinkTrackCopy/LinkTrackCopy'));

const StructuredTextRenderer = ({
  data,
  iconClassName,
  isListItemClassName = "",
  isHeadingClassName = "",
  isHeadingLevelClassName = "",
  isHeadingLevel,
  isParagraphClassName = "subtle",
  isLinkClassName = "subtle",
  linkWrapperClassName,
  imageClassName,
  onButtonClick,
  textLinkClassName,
  buttonClassName,
  isTextContent,
}) => {
  if (!data || !data.value) {
    return null;
  }

  return (
    <StructuredText
      data={data}
      renderBlock={({ record }) => {
        switch (record.__typename) {
          case "DatoCmsLinktrackcopy":
            return <LinkTrackCopy {...record} />;
          case "DatoCmsQuoteblock":
            return <QuoteBlock {...record} />;
          case "DatoCmsVideoplayer":
            return (
              <Video src={record.video.video.mp4Url} controlPlayer={true} />
            );
          case "DatoCmsIcon":
            return (
              <Icon
                name={record.iconType}
                style={{ fill: `${record.iconColor.hex}` }}
                className={iconClassName}
              />
            );
          case "DatoCmsAppdownload":
            return <AppDownloads block={record} />;
          case "DatoCmsDownloaddropdown":
            return <DownloadDropdown block={record} />;
          case "DatoCmsTablereference":
            return <Table block={record} />;
          case "DatoCmsPublicationgrid":
            return <PublicationGrid block={record} isTextContent={isTextContent} />;
          case "DatoCmsList":
            return <List block={record} />;
          case "DatoCmsPlaylist":
            return <Playlist block={record} />;
          case "DatoCmsImage":
            const focalPoint = record.image.focalPoint
            return (
              <GatsbyImage
                className={imageClassName}
                imgClassName="borderRadiusPop"
                image={record.image.gatsbyImageData}
                alt={record.image.alt}
                objectPosition={focalPoint && `${record.image.focalPoint.x * 100}% ${record.image.focalPoint.y * 100}%`}
              />
            );
          case "DatoCmsLink":
            return (
              <LinkButton
                onClick={onButtonClick}
                type={record.linkType}
                text={record.text}
                isExternal={record.pressForExternalLinking}
                buttonSize={record.buttonSize}
                internalLink={record.slug}
                externalLink={record.externalLink}
                textLinkClassName={textLinkClassName}
                buttonClassName={buttonClassName}
                className={linkWrapperClassName}
                trackingName={record.trackingName}
                underlineText={record.underlineText}
              />
            );
          default:
            return null;
        }
      }}
      customMarkRules={[
        // convert "redHighlight" marks into <span> with correct styling
        renderMarkRule("redHighlight", ({ mark, children, key }) => {
          return (
            <span key={key} style={{ color: colors.SOUNDTRACK_RED }}>
              {children}
            </span>
          );
        }),
      ]}
      customNodeRules={[
        renderNodeRule(
          isHeading,
          ({ node, adapter: { renderNode }, children, key }) => {
            return renderNode(
              `h${node.level}`,
              {
                key,
                className:
                  node.level === isHeadingLevel
                    ? isHeadingLevelClassName
                    : isHeadingClassName,
              },
              children
            );
          }
        ),
        renderNodeRule(
          isParagraph,
          ({ node, adapter: { renderNode }, children, key }) => {
            const paragraphClassName = node.style
              ? `${node.style} ${isParagraphClassName}`
              : isParagraphClassName;
            return renderNode(
              "p",
              { key, className: paragraphClassName },
              children
            );
          }
        ),
        renderNodeRule(
          isLink,
          ({ node, adapter: { renderNode }, children, key }) => {
            const target = node.meta && node.meta[0].value
            if (isExternalLink(node.url)) {
              return renderNode(
                "a",
                {
                  key,
                  className: isLinkClassName,
                  href: node.url,
                  target,
                  rel: "noreferrer",
                },
                children
              );
            } else {
              return renderNode(
                Link,
                { key, className: isLinkClassName, to: node.url, target },
                children
              );
            }
          }
        ),
        renderNodeRule(
          isListItem,
          ({ node, adapter: { renderNode }, children, key }) => {
            return renderNode(
              "li",
              { key, className: isListItemClassName },
              children
            );
          }
        ),
      ]}
    />
  );
};

export default StructuredTextRenderer;

StructuredTextRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  isHeadingClassName: PropTypes.string,
  isHeadingLevelClassName: PropTypes.string,
  isHeadingLevel: PropTypes.number,
  isParagraphClassName: PropTypes.string,
  isLinkClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  onButtonClick: PropTypes.func,
  textLinkClassName: PropTypes.string,
  isListItemClassName: PropTypes.string,
  linkWrapperClassName: PropTypes.string,
};
