const LIGHT_COLORS = ["#ffffff", "#F6EFEC" ,"#f1f1f1","#ffa400","#fab3a9","#a18bfa"];
const DARK_COLORS = ["#f23440", "#4c2b46", "#373239", "#3c0a5b", "#161616"];
const SOUNDTRACK_RED = '#f23440'

export function isLightColor(hex) {
  return LIGHT_COLORS.includes(hex);
}

export function isDarkColor(hex) {
  return DARK_COLORS.includes(hex);
}

const colors = {
  isLightColor,
  isDarkColor,
  SOUNDTRACK_RED,
};

export default colors;
