import { participate } from '../lib/tracking'
 import {
   EXPERIMENTS_PARTICIPATE,
   EXPERIMENTS_NAMESPACE,
 } from '@soundtrackyourbrand/experiments.js'

 const EXPERIMENTS_KEY = "experiments";

 export function loadExperiments() {
   try {
     const serializedState = localStorage.getItem(EXPERIMENTS_KEY);
     if (!serializedState) return undefined;
     return {[EXPERIMENTS_NAMESPACE]: JSON.parse(serializedState)};
   } catch (e) {
     return undefined;
   }
 }

 export async function saveExperiments(state) {
   try {
     const serializedState = JSON.stringify(state[EXPERIMENTS_NAMESPACE]);
     localStorage.setItem(EXPERIMENTS_KEY, serializedState);
   } catch (e) {
     // Ignore
   }
 }

 // Redux middleware for forwarding participation data to @soundtrackyourbrand/tracking.js
 export const experimentsMiddleware = _ => {
   return next => action => {
     if (action.type === EXPERIMENTS_PARTICIPATE) {
       participate(action.name, action.variation)
     }
     return next(action)
   }
 }