import React, { useEffect, useState, useRef } from "react";
import { createTrackingEvent } from "lib/tracking";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CustomSprite from "../CustomSprite/CustomSprite";
import pricing from "lib/pricing";
import overrides from "lib/overrides";
import objects from "lib/objects";

const Layout = ({ children }) => {
  const mainRef = useRef();

  const [prices, setPrices] = useState({ loading: true });
  const [voucher, setVoucher] = useState(null);
  const [voucherDiscountPercentage, setVoucherDiscountPercentage] =
    useState(null);

  let navbarColors = "redBlack"; // Default navbar

  if (children.props.data) {
    // If the page has a specific navbar, apply it
    const pageType = Object.keys(children.props.data)[0];
    if (children.props.data[pageType].slug === "/") {
      // If on home set new pop1 navbarcolor
      navbarColors = "redWhitePop";
    } else {
      navbarColors = children.props.data[pageType].navbarColors;
    }
  }

  const updateVoucher = (voucher) => {
    overrides.set("voucher", voucher);
    setVoucher(voucher);
  };

  const pricingStore = {
    pricing: {
      prices,
      voucher,
      voucherDiscountPercentage,
      hasVoucher: voucher !== null,
      updateVoucher,
      hasEssential: prices.hasOwnProperty("essential"),
      hasUnlimited: prices.hasOwnProperty("unlimited"),
      hasPricePlan: (pricePlan) => {
        return prices.hasOwnProperty(pricePlan);
      },
      renderPrice: (...args) => {
        return pricing.renderPrice(prices, ...args);
      },
      PRICING_PROPERTIES: pricing.PRICING_PROPERTIES,
    },
  };

  useEffect(() => {
    const currentVoucher = overrides.get("voucher");
    if (currentVoucher) {
      setVoucher(currentVoucher);
    } //Track click
    const trackClick = (event) => {
      // When clicking on UI Buttons that are links the event target is a span with the class _btn-content and the link is in the parentNode
      const elementLink = event.target.href ?? event.target.parentNode.href;
      createTrackingEvent("Storefront - click", {
        "Element Text Content": event.target.textContent ?? "",
        "Element Link": elementLink ?? "",
      });
    };
    document.addEventListener("mousedown", trackClick);
    return () => {
      document.removeEventListener("mousedown", trackClick);
    };
  }, []);

  useEffect(() => {
    //Track page view
    const trackPageView = (path) => {
      createTrackingEvent(
        "Storefront - Page View",
        {},
      );
    };
    trackPageView(children.key);
  }, [children]);

  useEffect(() => {
    let active = true;

    async function getPrices() {
      const priceInformation = await pricing.getPrices(voucher);
      if (active) {
        const voucherDiscountPercentage = objects.get(
          priceInformation,
          pricing.PRICING_PROPERTIES.VOUCHER_DISCOUNT_PERCENTAGE
        );
        setPrices({ loading: false, ...priceInformation });
        if (voucherDiscountPercentage) {
          setVoucherDiscountPercentage(voucherDiscountPercentage);
        }
      }
    }
    getPrices();
    return () => {
      active = false;
    };
  }, [voucher]);

  return (
    <PricingContext.Provider value={pricingStore}>
      <Helmet>
        <html lang="en" />
        <meta name="google-site-verification" content="TBqRJe3ts1NMFOHjNlcgK_sgTE7uaksMzUdJfWHz954" />
        <meta name="application-name" content="Soundtrack Your Brand" />
        <meta
          name="apple-mobile-web-app-title"
          content="Soundtrack Your Brand"
        />
        <meta name="msapplication-TileColor" content="#f23440" />
        <meta name="theme-color" content="#f23440" />
        <link rel="shortcut icon" sizes="48x48" href="/favicon.ico?v=2" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon-192.png?v=2"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon-180.png?v=2"
        />
        <link rel="mask-icon" href="/favicon-mask.svg?v=2" color="#161616" />
        <link rel="manifest" href="/site.webmanifest?v=2" />
      </Helmet>
      <CustomSprite />
      <Header navbarColors={navbarColors} popExperiment mainRef={mainRef} />
      <main ref={mainRef} className="rebrand">
        {children}
      </main>
      <Footer />
    </PricingContext.Provider>
  );
};

export default Layout;

export const PricingContext = React.createContext();
