import * as cookie from 'tiny-cookie'
import queryString from 'lib/query-string'
import urls from 'lib/urls'

export const AVAILABLE = [
  'country',
  'voucher',
]

export const query = queryString.fromURL()

// Overrides are specified using query params, and are stored as cookies
for (const option of AVAILABLE) {
  if (query && query[option] != null) {
    set(option, query[option])
  }
}

export function get(option) {
  return query[option] || cookie.get(option)
}

export function set(option, value) {
  cookie.set(option, value, { expires: 180, domain: urls.current().cookieDomain })
}

const overrides = {
  get,
  set,
  query,
  AVAILABLE,
};

export default overrides;