import ls from 'lib/local-storage'

export function param(object) {
  return Object.keys(object).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(object[k])).join('&')
}

export const memoize = {
  localStorage(key, ttl) {
    return fn => {
      return (...args) => {
        if (ls.get(key)) {
          return ls.get(key)
        }

        return fn(...args).then(val => {
          ls.set(key, val, ttl)
          return val
        })
      }
    }
  }
}

/*
 * Can be used both as debounce and as
 * and as a throttle (by specifying immediate = true)
 *
 * callAfterThrottle = true makes the throttled function execute once again
 * AFTER the wait time has passed
 */
export function debounce(func, wait, immediate, callAfterThrottle) {
  let timeout

  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate || callAfterThrottle) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait || 200)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export function single(fn) {
  let inFlight = null

  return function () {
    return new Promise((resolve, reject) => {
      if (!inFlight) {
        inFlight = fn.apply(this, [].slice.call(arguments))
      }
      inFlight.then(resolve, reject).finally(() => inFlight = null)
    })
  }
}

export function isExternalLink(url) {
  if (typeof document === 'undefined') return null

  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
}

export function date(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}